import React from "react"
import { graphql } from 'gatsby'
import {Col, Row, Container } from 'react-bootstrap';
import HeaderTwo from "../components/headerTwo/headerTwo"
import Footer from "../components/footer/footer"
import StaticSubscribe from "../components/Static/StaticSubscribe/StaticSubscribe"
import InterestedVideo from "../components/Static/InterestedVideo/InterestedVideo"
import Breadcrumbs from "../components/MgServices/Breadcrumb/news-breadcrumb"
import SEO from "../components/seo"

import ShareImg from "../images/share.png";

// import { GetURL } from "../components/common/site/functions";

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";


import {
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon
} from "react-share";

import { inViewOptions } from '../components/utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const containerVariants = {
	hidden: {
	  opacity: 0,
	  y: -30,
	},
	visible: {
	  opacity: 1,
	  y: 0,
	  transition: {
		ease: [0.33, 1, 0.68, 1],
		duration: 0.4
	  }
	}
}

const VideoDetails = (props) => {
	
    const data = props.data.strapiVideos
    const other_videos = props.data.allStrapiVideos.edges
    const menus = props.data.allStrapiAllMenus.edges
    let url = 'videos'

	// console.log("vidd",data.Video_Category)
    // if(data.Category != null) {
    // 	url = GetURL(data.Category.Choose_Category_Base_URL).split( '/' ).pop()
    // }

    const shareurl = typeof window !== 'undefined' ? window.location.href : ''
    const pathname = typeof window !== 'undefined' ? window.location.pathname : ''

	data.Video_Category.map((cate, i) => {
		if(pathname.includes(cate.URL)) {
			url = cate.URL
		}
	})

	const [Shareicons,setShareicons] = React.useState(false);
	const openShareicons = () => {
	  setShareicons(true);
	  if(Shareicons === true) {
	    setShareicons(false);
	  }
	}

	return(
		<div
			className="content video-details"
		>
			<HeaderTwo />

			<SEO location={props.location} title={data.Meta_Title ? data.Meta_Title : data.Title} description={data.Meta_Description ? data.Meta_Description : data.Title} />
			<InView {...inViewOptions}>
				{({ ref, inView }) => (
					<Container>
						<Row>
							<Col xs={12}>
								<motion.div
									className="head"
									ref={ref}
									initial="hidden"
									animate={inView ? "visible" : "hidden"}
									variants={containerVariants}	
								>
									<Breadcrumbs url={url} tag="contact" category={data.Videos_Parent_Category.Name} Title={data.Title} sub_category={data.Category ? data.Category.Build_Separate_Page : ''} sub_category_name={data.Category ? data.Category.Name : ''} />
									
									<h1>{data.Title}</h1>
									{data.description && data.description !== " " && <p className="news-description">{data.description}</p>}
									<p className="video-dots-details">
									{data.Videos_Parent_Category &&
									<span>{data.Videos_Parent_Category.Name}</span> 
									}
									<span className="share" onClick={openShareicons} role="presentation" >
									<img loading="lazy" src={ShareImg} alt="" /> Share
									{Shareicons &&
										<div onClick={openShareicons} className="property-share-social-icons" role="presentation">
										<FacebookShareButton url={shareurl} className="my-share-button facebook-share">
											<FacebookIcon size={32} round={false} borderRadius={`10`} />
										</FacebookShareButton>
										<TwitterShareButton url={shareurl} className="my-share-button twitter-share">
											<TwitterIcon size={32} round={false} borderRadius={`10`} />
										</TwitterShareButton>
										<LinkedinShareButton url={shareurl} className="my-share-button linkedin-share">
											<LinkedinIcon size={32} round={false} borderRadius={`10`} />
										</LinkedinShareButton>
										<WhatsappShareButton url={shareurl} className="my-share-button whatsapp-share">
											<WhatsappIcon size={32} round={false} borderRadius={`10`} />
										</WhatsappShareButton>
										</div>
									}
									</span>
									</p>
								</motion.div>
							</Col>
						</Row>
					</Container>
				)}
			</InView>
			<StaticSubscribe image={data.Video_Background_Image} data={data} url={data.Embed_Video_URL}/>
			<InterestedVideo videos={other_videos} menus={menus}/>
			<Footer Select_Popular_Search="Static_Insights" popularSearch="static"/>
		</div>
    )
}

export default VideoDetails


export const pageQuery = graphql`
  query VideoDetailQuery($slug: String!) {
      strapiVideos(URL: {eq: $slug}) {
	    Title
		description
        Meta_Description
        Meta_Title
	    Video_Background_Image {
	    	internal {
	    	  description
	    	}
	    }
	    Embed_Video_URL
	    Date(formatString: "DD/MM/YYYY")
	    Video_Category {
	      Name
		  URL
	      Choose_Category_Base_URL
	      Build_Separate_Page
	    }
	    Videos_Parent_Category {
	      Name
	    }
	    strapiId
	    imagetransforms {
	      Tile_Image_Transforms
	      Video_Background_Image_Transforms
	    }
      }

	  allStrapiVideos(filter: {Publish: {eq: true}, URL: {ne: $slug}}, sort: {order: ASC, fields: Date}, limit: 10) {
	    edges {
	      node {
	        URL
	        Title
			description
	        strapiId
	        id
	        Tile_Image {
	          internal {
	            description
	          }
	        }
	        Embed_Video_URL
	        Video_Category {
	          Choose_Category_Base_URL
	        }
            Videos_Parent_Category {
              URL
            }
		    imagetransforms {
		      Tile_Image_Transforms
		    }
	      }
	    }
	  }

	  allStrapiAllMenus {
	    edges {
	      node {
	        URL
	        Alias
	        Sub_Parent {
	          Alias
	          URL
	          id
	        }
	        Main_Parent {
	          Alias
	          URL
	          id
	        }
	        Link_Type
	        id
	        strapiId
	      }
	    }
	  }

    }
`
