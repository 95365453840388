import React, { useState, useEffect, useRef } from "react";
import ModalVideo from 'react-modal-video'
import { Modal } from "react-bootstrap"
import 'react-modal-video/scss/modal-video.scss';
import { createPortal } from 'react-dom';
import { Link } from "@StarberryUtils";
import ReactPlayer from 'react-player'
import { useLocation } from "@reach/router"
import $ from "jquery"

import {
    isIOS,
    isMobileOnly,
    isBrowser,
    isMobile
} from "react-device-detect";

const ModalWindow = ({ children }) => {
    // element to which the modal will be rendered
    const el = document.createElement("div");
    // add a class to the container div for styling
    // el.className = "modal-portal-container";
    useEffect(() => {
        const modalRoot = document.body;
        // append to root when the children of Modal are mounted
        modalRoot.appendChild(el);
        // do a cleanup
        return () => {
            modalRoot.removeChild(el);
        };
    }, [el]);

    return(
        createPortal(
            children,
            el
        )
    )
}


const CustomVideo = (props) => {
    const [isOpen, setOpen] = useState(false);
    const [videotype, setVideoType] = useState('youtube');
    const [youtubeid, setYoutubeID] = useState('youtube');

     const trackerVideo = (event) => {    
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'Video Play Btn',
          'formType': event,
          'formId': 'Video Play',
          'formName': 'Video',
          'formLabel': 'Video'
        });
    }

  const [isPlay, setPlay] = useState(false);
  const vidRef = useRef(null);
  const [show, setShow] = useState(false);
  const [play, setPlayvideo] = useState(true);
  const [mute, setMute] = useState(true);
  const [controls, setVideoControls] = useState(false);
  
  
  const [showHTMLVideo, setHTMLVideo] = React.useState(false);
  const pausevideo = (ref) => {
    setPlayvideo(false)
    setVideoControls(false)
  
  }
  const playvideo = (ref) => {
    setPlayvideo(true)
    setVideoControls(false)
    setTimeout(function(){ $(".modal-bg-black").hide() }, 1700);
  }
  const showControls = () => {
    setVideoControls(true)
  }
  
  const [showFullDescription, setShowFullDescription] = useState(false);

  let shortDescription = ""
  let Description = <></>

  if(props.titleOn && props.description) {
    const description = props.description
    let descLength = 250

    if(isMobile) {
        descLength = 150
    }
    if(isMobileOnly) {
        descLength = 70
    }

    shortDescription = description.length > descLength ? props.description.substring(0, descLength) + "..." : description;

    Description = (
        <>
          <p className="video-news-description">
            {showFullDescription ? props.description : shortDescription}{" "}
            {description.length > descLength && <span className="read-more"
            onClick={() => {
              setShowFullDescription(!showFullDescription)
            }}
          >{showFullDescription ? "read less" : "read more"}</span>}
            
          </p>
        </>
      )
  }


    function checkvideo(url) {
        let url_one = String(url)
        let youtube = url_one.indexOf("youtube.com") > -1
        let matter = url_one.indexOf("matterport.com") > -1
        if(youtube == true) {
            setVideoType("youtube")
            setYoutubeID(url_one.split( '/' ).pop())
        }
        else if(matter == true) {
            setVideoType("matterport")
        }
    
        }
    
    return(
        <>
        {props.isvideoplay ?
        <span className="play-btn" tabIndex="0" onClick={() => { setHTMLVideo(true); playvideo(); setShow(true); props.toogleClick(props.j) }}><i className="icon-play-icon-video"></i></span>
        :
        props.titleOn ?
        <>
            <span tabIndex="0" onClick={() => { setHTMLVideo(true); playvideo(); setShow(true); props.toogleClick(props.j) }}>{props.title}</span>
            {Description}
        </>
        :
            <span className="play-btn" tabIndex="0" onClick={() => { setHTMLVideo(true); playvideo(); setShow(true); }}><i className="icon-play-icon"></i></span>
        }
            


            {showHTMLVideo  &&
                <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-video"
                aria-labelledby="example-custom-modal-styling-title"
                backdropClassName="video-backdrop"
                className="area-guide-full-video-popup"
                >
                <Modal.Header closeButton>
                </Modal.Header>
                    <Modal.Body>
                    {isMobile &&
                    <div className="modal-bg-black">
                    </div>
                    }
                    <ReactPlayer frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen autoplay ref={vidRef} onPlay={trackerVideo(props.title)} url={props.url} modestbranding="1"  controls={isMobile && isIOS ?true:false} autoplay={true} muted={isMobile && isIOS ? mute : false} playsinline={true} playing={play} onEnded={() => { setHTMLVideo(false) }} width='100%' height='100%'  previewTabIndex={0} light={false}/>
                    </Modal.Body>
                </Modal>

            }

        </>
    )
}

export default CustomVideo

